import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class HasRoleGuard implements CanActivate {
  constructor(private auth:AuthService, private router:Router) {}
  canActivate(route: ActivatedRouteSnapshot) : boolean {
    const allowedRole = route.data['role']
    const token = this.auth.getAuthToken();

    // If there is no token, the user is not authenticated.
    if (!token) {
      this.router.navigate(['/home'])
      return false
    }

    // Extract the user's roles from the token.
    const roles = this.auth.getClaims(token).roles

    // Check if the user has the required roles.
    if (allowedRole && !roles.some((role) => allowedRole.includes(role))) {
      this.auth.logoutZulema();
      this.router.navigate(['/main'])
      return false
    }

    // The user is authorized to access the route.
    return true
  }
  
}
