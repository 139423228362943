import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginCheckGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      const token = this.auth.getAuthToken();
      // If there is no token, the user is not authenticated.
  
      if (token) {
        const claims = this.auth.getClaimsFromToken();
        this.router.navigate(["/main"]); // Path a dashboard
        return false;
      } else {
        return true;
      }
      
  }
  
}
