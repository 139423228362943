import { Routes } from '@angular/router';
import { LoginCheckGuard } from './guards/login-check.guard';
import { HasRoleGuard } from './guards/has-role.guard';

export const routes: Routes = [
  {
    path: 'home',
    loadComponent: () => import('./home/home.page').then((m) => m.HomePage),
    canActivate: [LoginCheckGuard],
  },
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full',
  },
  /* Componentes */
  {
    path: 'main',
    loadComponent: () => import('./main-view/main-view.component').then((m) => m.MainViewComponent),
    canActivate: [HasRoleGuard],
    data: { role: 'ROLE_TRANSPORTISTA' },
  },
  {
    path: 'cargas',
    loadComponent: () => import('./cargas/cargas.component').then((m) => m.CargasComponent),
    canActivate: [HasRoleGuard],
    data: { role: 'ROLE_TRANSPORTISTA' },
  },
  {
    path: 'notificaciones',
    loadComponent: () => import('./notificaciones/notificaciones.component').then((m) => m.NotificacionesComponent),
    canActivate: [HasRoleGuard],
    data: { role: 'ROLE_TRANSPORTISTA' },
  },
  {
    path: 'mis-cargas',
    loadComponent: () => import('./mis-cargas/mis-cargas.component').then((m) => m.MisCargasComponent),
    canActivate: [HasRoleGuard],
    data: { role: 'ROLE_TRANSPORTISTA' },
  },
  {
    path: 'resultados-cargas',
    loadComponent: () => import('./resultados-cargas/resultados-cargas.component').then((m) => m.ResultadosCargasComponent),
    canActivate: [HasRoleGuard],
    data: { role: 'ROLE_TRANSPORTISTA' },
  }
  ,
  {
    path: 'busqueda-localidad',
    loadComponent: () => import('./modal-busqueda/modal-busqueda.component').then((m) => m.ModalBusquedaComponent),
    canActivate: [HasRoleGuard],
    data: { role: 'ROLE_TRANSPORTISTA' },
  }
];
