import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private url = environment.url + '/transporte';

  private userZulema = new BehaviorSubject<any | null>(null);
  
  constructor(private http: HttpClient) { }

  loginZulema(user: { email: string; password: string }) {
    const userTest = {
      usuarioEmail: user.email,
      usuarioPassword: user.password,
    };
    const headersToSend = new HttpHeaders()
    .set('content-type', 'application/json; charset=UTF-8')
    .set('Access-Control-Allow-Origin', '*')
    return this.http
      .post(this.url + '/login', userTest, {
        headers: headersToSend
      })
      .pipe(tap((x: any) => localStorage.setItem('jwt', x.token)))
      .pipe(tap((x: any) => this.userZulema.next(jwtDecode(x.token))));
  }

  getAuthToken() {
    return localStorage.getItem('jwt');
  }

  getClaims(token: string) {
    const decodedToken = jwtDecode(token);
    const claims = decodedToken as { userId: number; roles: string[] };
    return claims;
  }

  getClaimsFromToken() {
    const token = this.getAuthToken();
    if (token) {
      return this.getClaims(token);
    }
    return null;
  }

  refreshToken() {
    return this.http.get<string>(this.url + '/refreshtoken');
  }

  logoutZulema() {
    localStorage.removeItem('jwt');
  }

  getEmail() {
    const token = this.getAuthToken();
    const decodedToken = jwtDecode(token!);
    const email = decodedToken as { sub: string };
    return email.sub;
  }
  
  getUsuario(id: string) {
    return this.http.get(this.url + "/user/" + id);
  }
}
